import {DeviceUUID} from "device-uuid";

export default () => ({
    uuid: new DeviceUUID().get(),
    host: process.env.VUE_APP_URL,
    target: {
        number: false,
        date: false
    },
    cleanMeta: false,
    targetDate: new Date,
    list: [],
    start: 1,
    order: 1,
    drag: false,
    dropHeaders: { "Cache-Control": "", "X-Requested-With": "" },
    progress: 0,
    showProgress: false,
    trash: 0
})