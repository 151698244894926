<template>
  <draggable
    class="list-group mb-4 w-100"
    tag="transition-group"
    :component-data="{
      tag: 'ol',
      type: 'transition-group',
      name: !$store.state.pdf.drag ? 'flip-list' : null,
    }"
    v-model="$store.state.pdf.list"
    v-bind="dragOptions"
    @start="$store.state.pdf.drag = true"
    @end="$store.state.pdf.drag = false"
    item-key="order"
    @update="$store.commit('pdf/sort')"
  >
    <template #item="{ element }">
      <li class="list-group-item d-flex">
        <div>
          <b>{{ element.order }}</b> - {{ element.name }}
        </div>
        <div>
          <a
            v-show="element.link"
            :href="element.link"
            :download="`doc-${element.order}`"
            target="_blank"
          >
            <i class="fa fa-external-link" aria-hidden="true"></i>
          </a>
        </div>
      </li>
    </template>
  </draggable>
  <div v-if="showGenerateBtn" class="d-flex justify-content-center w-100">
    <button id="submit" class="btn btn-primary" @click="generatePDFs">
      Generar
    </button>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    draggable,
  },
  methods: {
    ...mapActions("pdf", ["generate"]),
    generatePDFs() {
      this.$store.commit("pdf/setShowProgress", true);

      this.generate().then(() => {
        this.$store.commit("pdf/reboot");

        VEvent.config.globalProperties.$bus.$emit("resetDzFiles");

        Swal.fire("Documentos procesados correctamente!", "", "success");
      });
    },
  },
  computed: {
    ...mapGetters("pdf", ["dragOptions", "showGenerateBtn"]),
  },
};
</script>