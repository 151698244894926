import { saveAs } from 'file-saver';

function download_files(url, title) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function(){
        const file = new Blob([xhr.response],{
            type: 'application/pdf'
        });

        saveAs(file, title)
    }
    xhr.send();
  }

  export default download_files