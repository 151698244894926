
export const dragOptions = ( state ) => {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
    };
}

export const hasDocuments = ( state ) => {
    return state.list.length;
}

export const uploadURL = ( state ) => {
    return `${state.host}/upload.php`;
}

export const formatedDate = ( state ) => {
    const separator = '/';
    const day = ('0' + state.targetDate.getDate()).slice(-2);
    const month = ('0' + (state.targetDate.getMonth() + 1)).slice(-2);
    return `${day}${separator}${month}${separator}${state.targetDate.getFullYear()}`;
}

export const showGenerateBtn = ( state ) => {
    return state.target.number || state.target.date;
}

export const progress = ( state ) => {
    return Math.ceil(state.progress)
}

export const showProgress = ( state ) => {
    return state.showProgress
}

export const trashFull = ( state ) => {
    return state.trash > 0
}