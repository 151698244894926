<template>
  <div id="dock-container">
    <div id="dock">
      <ul>
        <li>
          <span>Trash</span>
          <a href="#" @click.prevent="clear">
            <img v-show="!trashFull" src="/img/dock/trash-empty.png" />
            <img v-show="trashFull" src="/img/dock/trash-full.png" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  methods:{
    ...mapActions('pdf', ['checkTrash', 'clearTrash']),
    async clear(){
      await this.clearTrash()
      Swal.fire("Documentos borrados correctamente!", "", "success");
    }
  },
  computed: {
    ...mapGetters("pdf", ["trashFull"]),
  },
  async created(){
    await this.checkTrash()
  }
};
</script>