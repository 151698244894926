import { createApp, Vue } from 'vue'
import App from './App.vue'
import { store } from './store'
import $bus from './eventBus.js';

import DropZone from 'dropzone-vue'

window.VEvent = createApp()
window.VEvent.config.globalProperties.$bus = $bus;

const app = createApp(App)

app.use(store)
app.use(DropZone)

app.mount('#app')
