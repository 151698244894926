<template>
  <div class="d-flex justify-content-center mb-4">
    <button
      class="btn mx-1"
      :class="$store.state.pdf.target.number ? 'btn-primary' : 'btn-secondary'"
      @click="$store.state.pdf.target.number = !$store.state.pdf.target.number"
    >
      Número
    </button>
    <button
      class="btn mx-1"
      :class="$store.state.pdf.target.date ? 'btn-primary' : 'btn-secondary'"
      @click="$store.state.pdf.target.date = !$store.state.pdf.target.date"
    >
      Fecha
    </button>
    <button
      class="btn mx-1"
      :class="$store.state.pdf.cleanMeta ? 'btn-primary' : 'btn-secondary'"
      @click="$store.state.pdf.cleanMeta = !$store.state.pdf.cleanMeta"
    >
      Limpiar Meta
    </button>
  </div>
  <div
    v-if="$store.state.pdf.target.number"
    class="d-flex align-items-center justify-content-center"
  >
    <p class="pe-3">Número de documento por el que quieres iniciar:</p>
    <div class="input-group mb-3 start-block">
      <button class="btn btn-primary" type="button" @click="decreaseStart">
        <b>-</b>
      </button>
      <input
        v-model="$store.state.pdf.start"
        @change="$store.commit('pdf/onOrderChange')"
        type="text"
        class="form-control text-center start-number"
      />
      <button class="btn btn-primary" type="button" @click="increaseStart">
        <b>+</b>
      </button>
    </div>
  </div>
  <div
    v-if="$store.state.pdf.target.date"
    class="d-flex align-items-center justify-content-center"
  >
    <p class="pe-3">Fecha de los documentos:</p>
    <div class="input-group mb-3 date-block">
      <Datepicker
        v-model="$store.state.pdf.targetDate"
        format="dd/MM/yyyy"
        :autoApply="true"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { mapActions } from "vuex";

export default {
  components: {
    Datepicker,
  },
  methods: {
    ...mapActions("pdf", ["increaseStart", "decreaseStart"]),
  },
};
</script>
