<template>
    <div v-if="visible">
        <input class="retrigger" id="retrigger--1" type="radio" name="rerun" />
        <input
            class="retrigger"
            id="retrigger--2"
            type="radio"
            name="rerun"
            checked="checked"
        />
        <div class="bg d-flex justify-content-center align-items-center">
            <img src="/img/marvel-logo.png" alt="">
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            visible: true
        }
    },
    methods: {
        ...mapActions("pdf", ["boydBg"]),
    },
    created(){
        this.boydBg()
        setTimeout(() => {
           this.visible = false
        }, 2000)
    }
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Passion+One);
body {
  background: #d30215;
  overflow: hidden;
  margin: 0;
  font-family: "Passion One", impact;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("/public/img/preloader.jpg");
  -webkit-animation: fade 2s steps(6) forwards;
  animation: fade 2s steps(6) forwards;
  z-index: 9;
}
.bg img{
    max-width: 400px;
    display: block;
    margin: 0 auto;
}
#retrigger--1:checked ~ .bg {
  -webkit-animation-name: fade--1;
  animation-name: fade--1;
}
#retrigger--2:checked ~ .bg {
  -webkit-animation-name: fade--2;
  animation-name: fade--2;
}
@-webkit-keyframes fade--1 {
  0% {
    background-position: 0 0;
    opacity: 1;
  }
  50% {
    background-position: 2000% 2000%;
    opacity: 0.6;
  }
  100% {
    background-position: -2000% -2000%;
    opacity: 0;
  }
}
@keyframes fade--1 {
  0% {
    background-position: 0 0;
    opacity: 1;
  }
  50% {
    background-position: 2000% 2000%;
    opacity: 0.6;
  }
  100% {
    background-position: -2000% -2000%;
    opacity: 0;
  }
}
@-webkit-keyframes fade--2 {
  0% {
    background-position: 0 0;
    opacity: 1;
  }
  50% {
    background-position: 2000% 2000%;
    opacity: 0.6;
  }
  100% {
    background-position: -2000% -2000%;
    opacity: 0;
  }
}
@keyframes fade--2 {
  0% {
    background-position: 0 0;
    opacity: 1;
  }
  50% {
    background-position: 2000% 2000%;
    opacity: 0.6;
  }
  100% {
    background-position: -2000% -2000%;
    opacity: 0;
  }
}

.pane {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(1.3);
  perspective: 700px;
}

.rotate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate3d(1, -1, 0, 40deg) scale(2);
  transform-style: preserve-3d;
  -webkit-animation: rotate 6s 2s forwards;
  animation: rotate 6s 2s forwards;
}
#retrigger--1:checked ~ .pane .rotate {
  -webkit-animation-name: rotate--1;
  animation-name: rotate--1;
}
#retrigger--2:checked ~ .pane .rotate {
  -webkit-animation-name: rotate--2;
  animation-name: rotate--2;
}
@-webkit-keyframes rotate--1 {
  0% {
    transform: translate(-50%, -50%) rotate3d(1, -1, 0, 40deg) scale(2);
  }
  50% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1);
  }
}
@keyframes rotate--1 {
  0% {
    transform: translate(-50%, -50%) rotate3d(1, -1, 0, 40deg) scale(2);
  }
  50% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1);
  }
}
@-webkit-keyframes rotate--2 {
  0% {
    transform: translate(-50%, -50%) rotate3d(1, -1, 0, 40deg) scale(2);
  }
  50% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1);
  }
}
@keyframes rotate--2 {
  0% {
    transform: translate(-50%, -50%) rotate3d(1, -1, 0, 40deg) scale(2);
  }
  50% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) rotate3d(0, 0, 0, 0deg) scale(1);
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%);
  font-size: 9em;
  letter-spacing: -0.06em;
  -webkit-animation: hide 8s linear forwards;
  animation: hide 8s linear forwards;
  padding: 0 0.2em;
  line-height: 1;
  border: 0.02em solid black;
}
#retrigger--1:checked ~ .pane .logo {
  -webkit-animation-name: hide--1;
  animation-name: hide--1;
}
#retrigger--2:checked ~ .pane .logo {
  -webkit-animation-name: hide--2;
  animation-name: hide--2;
}
.logo:nth-child(1) {
  -webkit-animation: change 8s steps(30) forwards;
  animation: change 8s steps(30) forwards;
  background: url("/public/img/preloader.jpg");
  background-size: 2000px auto;
  -webkit-background-clip: text;
  -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
    drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
  color: transparent;
}
#retrigger--1:checked ~ .pane .logo:nth-child(1) {
  -webkit-animation-name: change--1;
  animation-name: change--1;
}
#retrigger--2:checked ~ .pane .logo:nth-child(1) {
  -webkit-animation-name: change--2;
  animation-name: change--2;
}
.logo:nth-child(2) {
  transform: translate3d(-50%, -50%, -2px);
}
.logo:nth-child(3) {
  transform: translate3d(-50%, -50%, -3px);
}
.logo:nth-child(4) {
  transform: translate3d(-50%, -50%, -4px);
}
.logo:nth-child(5) {
  transform: translate3d(-50%, -50%, -5px);
}
.logo:nth-child(6) {
  transform: translate3d(-50%, -50%, -6px);
}
.logo:nth-child(7) {
  transform: translate3d(-50%, -50%, -7px);
}
.logo:nth-child(8) {
  transform: translate3d(-50%, -50%, -8px);
}
.logo:nth-child(9) {
  transform: translate3d(-50%, -50%, -9px);
}
.logo:nth-child(10) {
  transform: translate3d(-50%, -50%, -10px);
}
.logo:nth-child(11) {
  transform: translate3d(-50%, -50%, -11px);
}
.logo:nth-child(12) {
  transform: translate3d(-50%, -50%, -12px);
}
.logo:nth-child(13) {
  transform: translate3d(-50%, -50%, -13px);
}
.logo:nth-child(14) {
  transform: translate3d(-50%, -50%, -14px);
}
.logo:nth-child(15) {
  transform: translate3d(-50%, -50%, -15px);
}
.logo:nth-child(16) {
  transform: translate3d(-50%, -50%, -16px);
}
.logo:nth-child(17) {
  transform: translate3d(-50%, -50%, -17px);
}
.logo:nth-child(18) {
  transform: translate3d(-50%, -50%, -18px);
}
.logo:nth-child(19) {
  transform: translate3d(-50%, -50%, -19px);
}
.logo:nth-child(20) {
  transform: translate3d(-50%, -50%, -20px);
}
@-webkit-keyframes hide--1 {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes hide--1 {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-webkit-keyframes hide--2 {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes hide--2 {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-webkit-keyframes change--1 {
  0% {
    background-position: 0 0;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: black;
  }
  50% {
    background-position: 200% 200%;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: white;
  }
  100% {
    background-position: -200% -200%;
    color: white;
    -webkit-filter: drop-shadow(0 0 0 white) drop-shadow(0 0 0 white)
      drop-shadow(0 0 0 white) drop-shadow(0 0 0 white);
    border-color: white;
  }
}
@keyframes change--1 {
  0% {
    background-position: 0 0;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: black;
  }
  50% {
    background-position: 200% 200%;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: white;
  }
  100% {
    background-position: -200% -200%;
    color: white;
    -webkit-filter: drop-shadow(0 0 0 white) drop-shadow(0 0 0 white)
      drop-shadow(0 0 0 white) drop-shadow(0 0 0 white);
    border-color: white;
  }
}
@-webkit-keyframes change--2 {
  0% {
    background-position: 0 0;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: black;
  }
  50% {
    background-position: 200% 200%;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: white;
  }
  100% {
    background-position: -200% -200%;
    color: white;
    -webkit-filter: drop-shadow(0 0 0 white) drop-shadow(0 0 0 white)
      drop-shadow(0 0 0 white) drop-shadow(0 0 0 white);
    border-color: white;
  }
}
@keyframes change--2 {
  0% {
    background-position: 0 0;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: black;
  }
  50% {
    background-position: 200% 200%;
    color: rgba(150, 150, 0, 0.4);
    -webkit-filter: drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)
      drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black);
    border-color: white;
  }
  100% {
    background-position: -200% -200%;
    color: white;
    -webkit-filter: drop-shadow(0 0 0 white) drop-shadow(0 0 0 white)
      drop-shadow(0 0 0 white) drop-shadow(0 0 0 white);
    border-color: white;
  }
}

.retrigger {
  position: absolute;
  left: -5em;
  opacity: 0;
}

@-webkit-keyframes buttons--1 {
  0%,
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes buttons--1 {
  0%,
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@-webkit-keyframes buttons--2 {
  0%,
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@keyframes buttons--2 {
  0%,
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
</style>