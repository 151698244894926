<template>
  <Preloader/>
  <div class="container">
    <div v-if="showProgress" class="row">
      <div class="col-md-12">
        <Counter />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center mt-4">LOPARTZAR</h1>
      </div>
    </div>
    <div class="row align-items-center" style="height: 100vh">
      <div
        :class="
          hasDocuments
            ? 'col-md-6'
            : 'col-lg-6 offset-lg-3'
        "
      >
        <div class="mb-4 w-100">
          <Dropzone />
        </div>
      </div>
      <div
        class="
          col-md-6
          pb-5
          d-flex
          align-items-center
          justify-content-center
          flex-column
        "
        v-if="hasDocuments"
      >
        <Header />
        <Menu />
        <DocumentsList />
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <DockBar />
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "../components/Counter.vue";
import Dropzone from "../components/Dropzone.vue";
import Header from "../components/Header.vue";
import Menu from "../components/Menu.vue";
import DocumentsList from "../components/DocumentsList.vue";
import DockBar from "../components/DockBar.vue";
import Preloader from "../components/Preloader.vue";
import { mapGetters } from "vuex";

export default {
  name: "Pdf",
  display: "Transitions",
  components: {
    Counter,
    Dropzone,
    Header,
    Menu,
    DocumentsList,
    DockBar,
    Preloader,
  },
  computed: {
    ...mapGetters("pdf", ["hasDocuments", "showProgress"]),
  }
};
</script>
