
export const sort = ( state ) => {
    let order = state.start;
    state.list.forEach((item) => {
      item.order = order;
      order++;
    });
}

export const onOrderChange = ( state ) => {
    state.order = state.start;
    state.list.forEach((item) => {
      item.order = state.order;
      state.order++;
    });
}

export const sending = ( state, {files, xhr, formData} ) => {
    if(!formData.has("uuid")){
      formData.append("uuid", state.uuid);
    }
    xhr.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          const res = JSON.parse(xhr.response);
          if (res.status === "success") {
            state.list.push({
              id: res.id,
              order: state.order,
              name: files[0].name
            });
            state.order++;
          } else {
            alert("Se ha producido un error!");
          }
        }
    };
}

export const setProgress = (state, progress) => {
  state.progress = (state.list.length - progress) * 100 / state.list.length;
}

export const setShowProgress = (state, show) => {
  state.showProgress = show;
}

export const setTrash = (state, count) => {
  state.trash = count
}

export const reboot = ( state ) => {
    state.target = {
        number: false,
        date: false
    }
    state.targetDate = new Date,
    state.start = 1
    state.order = 1
    state.drag = false,
    state.progress =  0,
    state.showProgress = false
    state.list = []
  }