<template>
    <div id="loader" class="d-flex align-items-center justify-content-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <h3 class="text-white text-center">Procesando {{ this.progress }}%</h3>
                    <div class="progress">
                        <div 
                            class="progress-bar progress-bar-striped progress-bar-animated" 
                            role="progressbar" 
                            :aria-valuenow="progress" 
                            aria-valuemin="0" 
                            aria-valuemax="100" 
                            :style="width"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed:{
        ...mapGetters('pdf', ['progress']),
        width(){
            return `width: ${this.progress}%`;
        }
    }
}
</script>