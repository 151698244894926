<template>
  <img src="/img/deadpool.png" class="w-100" alt="" />
  <DropZone
    :headers="$store.state.pdf.dropHeaders"
    :maxFiles="Number(100)"
    :url="uploadURL"
    :uploadOnDrop="true"
    :multipleUpload="false"
    :parallelUpload="1"
    :withCredentials="false"
    :acceptedFiles="['pdf']"
    :class="hasDocuments ? 'min-h-200 d-flex' : 'min-h-250 d-flex'"
    :maxFileSize="50000000"
    @sending="onSending"
    ref="dropzoneEl"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    onSending(files, xhr, formData) {
      this.$store.commit("pdf/sending", { files, xhr, formData });
    },
  },
  computed: {
    ...mapGetters("pdf", [
      "hasDocuments",
      "uploadURL",
    ]),
  },
  mounted(){
    VEvent.config.globalProperties.$bus.$on("resetDzFiles", () => {
      this.$refs.dropzoneEl.all = {};
      this.$refs.dropzoneEl.ids = [];
    });
  }
};
</script>