import download_files from "@/helpers/downloader";
import axios from "axios";

export const generate = async ({ commit, state, getters, dispatch }) => {
	const docs = [...state.list];
	while (docs.length) {
		const documents = {};
		const item = docs[0];
		documents[item.id] = item.order;

		const data = new FormData();

		data.append("uuid", state.uuid);
		data.append("documents", JSON.stringify(documents));
		data.append("clean_meta", state.cleanMeta);

		if (state.target.number) {
			data.append("number", true);
		}
		if (state.target.date) {
			data.append("date", getters.formatedDate);
		}

		await requestGenerate(state, data, item);

		docs.shift();

		commit("setProgress", docs.length);
	}

	dispatch('checkTrash');
};

const requestGenerate = async (state, data, item) => {
	await axios
		.post(`${state.host}/generate.php`, data)
		.then(({ data }) => {
			if (data.status === "success") {
				const title = state.target.number ? `doc${item.order}.pdf` : item.name;
				const link = `${state.host}/dest/${state.uuid}/${title}`;
				setTimeout(() => {
					download_files(link, item.name);
				}, 500);
			}
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const checkTrash = async ({ commit, state }) => {
	await axios
		.get(`${state.host}/check_trash.php`, {
			params: { uuid: state.uuid },
		})
		.then(({ data }) => {
			if (data.status === "success") {
				commit('setTrash', parseInt(data.count))
			}
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const clearTrash = async ({ commit, state }) => {
	await axios
		.get(`${state.host}/cleaner.php`, {
			params: { uuid: state.uuid },
		})
		.then(({ data }) => {
			if (data.status === "success") {
				commit('setTrash', 0)
			}
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const increaseStart = ({ commit, state }) => {
	state.start++;
	commit("onOrderChange");
};

export const decreaseStart = ({ commit, state }) => {
	if (state.start > 1) {
		state.start--;
	}
	commit("onOrderChange");
};

export const boydBg = () => {
	setTimeout(()=>{
		document.querySelector('body').classList.add('loaded');
	}, 500)
}